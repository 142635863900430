import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import DataTable from "react-data-table-component";
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/action';
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.meta.exam_name_results,
    sortable: true,
    reorder: true
  },
  {
    id: 32,
    name: "Exam Date",
    selector: (row) => row.meta.exam_date_results,
    sortable: true,
    reorder: true
  },
  {
    id: 33,
    name: "Exam Total",
    selector: (row) => row.meta.exam_total_results,
    sortable: true,
    reorder: true,
    width: "150px"

  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.daily_exam_result}/${row.slug}`}>View</Link>,

  }
];
  export default function DailyExamAllResult() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [ExamResultData, setAllExamResultList] = React.useState([]);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const fetchExam = (slug) => {
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID;
    console.log(id)
    AdsGETApiAuth({
      "filter[meta_query][1][key]": "student_id_results",
      "filter[meta_query][1][value][0]": id,
      "filter[meta_query][1][compare]": "=",
      _fields: "id,slug,title,meta.exam_name_results,meta.exam_date_results,meta.exam_total_results"
    }, slugs.db_slug_daily_results)
      .then((response) => {
        setAllExamResultList(response.data)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
        if (err.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
      .finally(() => {
        setReload(false)
      })

  }
  useEffect(() => {
    fetchExam(location.pathname.slice(slugs.all_daily_results.length + 1))
  }, [])

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>History Per Kelas</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / History Per Kelas
        </div>
      </div>
      <a href={slugs.daily_exam}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Kelas</div>
      </a>
      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        <DataTable
          columns={columns}
          data={ExamResultData}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
        />
      </div>˝
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}