import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsDeleteApi, AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    width: "100px"
  },
  {
    id: 31,
    name: "Nama",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true
  },
  {
    id: 3121,
    name: "Joined Date",
    selector: (row) => row.date.slice(0, 10),
    sortable: true,
    reorder: true
  },
  // {
  //   id: 312,
  //   name: "Phone",
  //   selector: (row) => row.meta.user_phone_number,
  //   sortable: true,
  //   reorder: true
  // },
  // {
  //   id: 313,
  //   name: "Email",
  //   selector: (row) => row.meta.user_email,
  //   sortable: true,
  //   reorder: true
  // },
  {
    id: 32,
    name: "Verification",
    selector: (row) => row.meta.user_role == "subscriber" ? "Siswa" : row.meta.user_role == "student" ? "LPK admin" : "Web admin",
    sortable: true,
    reorder: true
  },
  {
    id: 321,
    name: "Kelas",
    selector: (row) => row.meta.batch_name,
    sortable: true,
    reorder: true,
    compact: true,
    width: "120px"
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.student_details}${row.slug}`}>View</Link>,

  }
];

export default function AllStudents() {
  const [Projects1, setProjects1] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Reload, setReload] = React.useState(false)
  const [SubscriberTotal, setSubscriberTotal] = React.useState(0)
  const [StudentTotal, setStudentTotal] = React.useState(0)
  const [AdminTotal, setAdminTotal] = React.useState(0)
  const [AllTotal, setAllTotal] = React.useState(0)
  const [serachName, setSearchName] = React.useState("")
  const [serachEmail, setSearchEmail] = React.useState("")
  const [serachPhone, setSearchPhone] = React.useState("")
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)
  const [Projects2, setProjects2] = React.useState([])
  const [choose, setChoose] = React.useState(0)
  const [batchList, setBatchList] = React.useState([])
  const [batchID, setBatchID] = React.useState("")
  const [batchName, setBatchName] = React.useState("")

  const GetTotal = (Role, setDataLength, all) => {
    setReload(true)
    setProjects1([])
    const allT = !all ? {
      "filter[meta_query][1][key]": "user_role",
      "filter[meta_query][1][value][0]": `${Role}`,
      "filter[meta_query][1][compare]": "=",
      per_page: 1
    } : { per_page: 1 }
    AdsGETApiAuth(allT, slugs.db_slug_user_data)
      .then((res) => {
        setDataLength(res.headers["x-wp-total"])
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_user_data)
      .then((res) => {
        setProjects1(res.data)
        setCustomersList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    GetAll()

  }, [])
  const GetAll = () => {
    GetAllProjects({ per_page: 4999 })
    GetTotal("subscriber", setSubscriberTotal, false)
    GetTotal("student", setStudentTotal, false)
    GetTotal("author", setAdminTotal, false)
    GetTotal("all", setAllTotal, true)
    BatchList()
  }

  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const columns2 = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.meta.user_id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Nama",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "HP",
      selector: (row) => row.meta.user_phone_number,
      sortable: true,
      reorder: true
    },
    {
      id: 313,
      name: "Email",
      selector: (row) => row.meta.user_email,
      sortable: true,
      reorder: true
    },
    {
      id: 32,
      name: "Status",
      selector: (row) => row.meta.user_role,
      sortable: true,
      reorder: true
    },
  ];

  const columnsBatch = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 31,
      name: "Nama Kelas",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Jumlah Siswa",
      selector: (row) => CustomersList.filter(a => a.meta.batch_id == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 312,
      name: "Status",
      selector: (row) => row.meta.batch_status,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "View",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          const ResultFilter = CustomersList.filter(a => a.meta.batch_id == row.id)
          setProjects1(ResultFilter)
          setChoose(1)
        }}
      >
        Lihat
      </div>,

    }
  ];

  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          {/* <h2>All Students</h2> */}
          <h2>Daftar Siswa</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          {/* <a href="/">Dashboard</a> / All Students */}
          <a href="/">Dashboard</a> / Semua Siswa
        </div>
      </div>
      <a href={slugs.new_student}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >
          {/* Add New Student */}
          Tambah Siswa Baru
        </div>
      </a>
      <div className="row">
        <div className="col-md-6">
          <div style={{ display: "inline", cursor: "pointer" }}>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(0)
              }}
            >
              {/* Delete Users */}
              Kelas ({batchList.length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
                setProjects1(CustomersList)
              }}
            >
              {/* All ({AllTotal}) | */}
              Hapus Siswa ({AllTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
                setProjects1(CustomersList.filter(a => a.meta.user_role == "subscriber"))
              }}
            >
              {/* Unverified ({CustomersList.filter(a => a.meta.user_role == "subscriber").length}) | */}
              Siswa ({CustomersList.filter(a => a.meta.user_role == "subscriber").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
                setProjects1(CustomersList.filter(a => a.meta.user_role == "student"))
              }}
            >
              {/* Verified ({CustomersList.filter(a => a.meta.user_role == "student").length}) | */}
              LPK Admin ({CustomersList.filter(a => a.meta.user_role == "student").length}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setChoose(1)
                setProjects1(CustomersList.filter(a => a.meta.user_role == "author"))
              }}
            >
              Web Admin ({AdminTotal}) |
            </div>
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                setDeleteUsersModal(true)
              }}
            >
              {/* Delete Users */}
              Hapus Siswa
            </div>

          </div>
        </div>
        <div className="col-md-6 row">
          <div className="col-4" />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachName} placeholder="Name"
            onChange={(event) => {
              setSearchName(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_name.includes(event.target.value)))
            }}
          />
          <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachEmail} placeholder="Email"
            onChange={(event) => {
              setSearchEmail(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_email.includes(event.target.value)))
            }}
          />
          <input className="col-2" style={{ margin: "1px 2px", padding: "3px 2px" }}
            value={serachPhone} placeholder="Phone"
            onChange={(event) => {
              setSearchPhone(event.target.value)
              setProjects1(CustomersList.filter(a => a.meta.user_phone_number.includes(event.target.value)))
            }}
          />
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px" }}>
        {choose == 1 &&
          <DataTable
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columns}
            data={Projects1}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            pagination
            selectableRows
            onSelectedRowsChange={(r) => {
              console.log("asdasd", r)
              setProjects2(r.selectedRows)
            }}
          />
        }
        {choose == 0 &&
          <DataTable
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columnsBatch}
            data={batchList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            pagination
            selectableRows={false}
          />
        }
      </div>
      {deleteUsersModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteUsersModal(false)
                  }}
                >X</div>
                {/* Do you really want to Delete all the user?<br /> */}
                Apakah anda yakin untuk menghapus siswa?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteUsersModal(false)
                      }}
                    >
                      Batal
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < Projects2.length; i++) {
                          setReload(true)
                          setDeleteUsersModal(false)
                          AdsDeleteApi("users", Projects2[i].meta.user_id)
                            .then((res) => {
                              AdsPUTApi({
                                status: "draft"
                              }, slugs.db_slug_user_data, Projects2[i].id)
                                .then((res) => {
                                  if (i == Projects2.length - 1) {
                                    GetAll()
                                  }
                                })
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Hapus semua siswa
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns2}
                    data={Projects2}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}