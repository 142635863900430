import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { LogoURL } from '../../Constant/api';
import { slugs } from '../../Constant/slugs';

export default function Home() {
  const dispatch = useDispatch()

  return (
    <div style={{height:"100vh",backgroundColor:"#F3F3F3"}}>
      <div className="row">
        <div style={{ margin: "10px" }} className="center">
          <img
            src={LogoURL}
            style={{ height: "30vh", width: "30vh", objectFit: "contain" }}
          />
        </div>
        <div className="row" >
          <Link to={slugs.daily_exam} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/class.jpeg"
            />
            <div className="home-fonts">
              Kelas
            </div>
          </Link>
          <Link to={slugs.exam} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/exam.jpeg"
            />
            <div className="home-fonts">
              PEMANTAPAN
            </div>
          </Link>
          <Link to={slugs.cbtexam} className="col-4 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/ubtspecial.jpeg"
            />
            <div className="home-fonts">
              UBT SPECIAL
            </div>
          </Link>

        </div>

        <div className="row">
          <Link to={slugs.book_list_user} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpkkoreabmn.com/wp-content/uploads/2023/10/LOGO-4.png"
            />
            <div className="home-fonts">
              MATERI BELAJAR
            </div>
          </Link>
          <Link to={"/profile"} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/profile.jpeg"
            />
            <div className="home-fonts">
              PROFIL
            </div>
          </Link>
          <Link to={"/my-exam-results"} className="col-4 center">
            <img
              className="home-icons"
              src="https://api.lpktayo.com/wp-content/uploads/2023/11/history.jpeg"
            />
            <div className="home-fonts">
              HISTORY TEST
            </div>
          </Link>

        </div>

      </div>
    </div>
  )
}